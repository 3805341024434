@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --color-primary: 0 0 0;
  --color-secondary: 0 0 0;
  --color-tertiary: 0 0 0;
  --color-quaternary: 0 0 0;
  --color-videojs-bar: 43, 51, 63, 0.7;
  --color-videojs-captions-color: 255 255 255;
  --color-videojs-captions-background: 0 0 0;
  --color-videojs-captions-size: "12pt";
  --color-videojs-captions-family: "Arial, Helvetica, sans-serif";
}

a {
  @apply text-brand-primary;
}
a:hover {
  @apply text-brand-secondary;
}

/* Markdown Styling */
.rendered-markdown p:not(:nth-last-of-type(1)) {
  margin-bottom: 0.75rem;
}

/* common styles */
.mobile-border-tertiary {
  @apply md:border-none border-2 border-solid border-brand-tertiary;
}
.mobile-bottom-border {
  @apply md:border-none border-0 border-b-2 border-solid border-brand-primary;
}
.mobile-top-border-light {
  @apply md:border-none border-0 border-t-2 border-solid border-brand-primary border-opacity-25;
}
.mobile-bottom-border-light {
  @apply md:border-none border-0 border-b-2 border-solid border-brand-primary border-opacity-25;
}
.brand-subheader {
  @apply pl-4 m-1 font-semibold text-brand-primary;
}
.common-text-button {
  @apply bg-transparent border-transparent font-semibold 
  text-brand-primary hover:text-brand-tertiary
  cursor-pointer even:cursor-pointer odd:cursor-pointer;
}

/* override videojs style sheet imported in index.html */
/* summary of component selectors - https://player.support.brightcove.com/styling/customizing-player-appearance.html */
/* ControlBar.prototype.options - https://github.com/videojs/video.js/blob/main/src/js/control-bar/control-bar.js */
.vjs-control-bar {
  background: rgba(var(--color-videojs-bar)) !important;
}
.vjs-control .vjs-button:hover,
.vjs-play-control:hover,
.skipBackBtn:hover,
.skipForwardBtn:hover,
.noteBtn:hover,
.theaterModeBtn:hover,
.vjs-playback-rate:hover,
.vjs-fullscreen-control:hover {
  filter: drop-shadow(0px 0px 3px rgba(255, 255, 255, 0.8));
}
.vjs-duration,
.vjs-time-divider,
.vjs-current-time {
  display: block !important;
}
.vjs-time-control {
  padding-right: 5px !important;
  padding-left: 5px !important;
  min-width: 1px !important;
}
/* shrink secondary control bar buttons */
.skipBackBtn,
.skipForwardBtn,
.vjs-chapters-button,
.noteBtn,
.theaterModeBtn,
.vjs-playback-rate,
.vjs-subs-caps-button,
.vjs-quality-selector {
  width: 30px !important;
}

/* add styling to captions */
.vjs-text-track-cue > * {
  font-size: var(--color-videojs-captions-size) !important;
  font-family: var(--color-videojs-captions-family) !important;
  color: rgba(var(--color-videojs-captions-color)) !important;
  background-color: rgba(var(--color-videojs-captions-background)) !important;
}
/* hide the captions settings item from the captions menu */
/* this was a pain to try to save user changes from, so the options were moved to the settings page */
.vjs-texttrack-settings {
  display: none;
}

/* add styling to videojs plugins */
.vjs-vtt-thumbnail-display {
  position: absolute;
  transition:
    transform 0.1s,
    opacity 0.2s;
  bottom: 85%;
  pointer-events: none;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.6);
}
.vjs-watermark {
  display: block;
}
.vjs-watermark-content {
  position: absolute;
  padding: 5px;
  height: 10%;
}
.vjs-watermark-content > * {
  /* The radius/bkg/shadow are temp - Blue tape: we need alt bkg logos (transparent bkg, white bkg, etc) per brand and not just navbarLogo and favicon */
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0 0 2px 2px rgba(255, 255, 255, 0.5);
  object-fit: cover;
  height: 100%;
}
.vjs-user-inactive.vjs-playing .vjs-watermark-fade {
  opacity: 0.5;
}
.vjs-watermark-top-right {
  right: 0;
  top: 0;
}
.vjs-watermark-top-left {
  left: 0;
  top: 0;
}
/* 30px to account for control bar */
.vjs-watermark-bottom-right {
  right: 0;
  bottom: 30px;
}
.vjs-watermark-bottom-left {
  left: 0;
  bottom: 30px;
}

.gray-scrollbar::-webkit-scrollbar-thumb {
  background-color: gray;
  border: 4px solid transparent;
  border-radius: 8px;
  background-clip: padding-box;
}

.white-scrollbar::-webkit-scrollbar-thumb {
  background-color: white;
  border: 4px solid transparent;
  border-radius: 8px;
  background-clip: padding-box;
}

.gray-scrollbar::-webkit-scrollbar,
.white-scrollbar::-webkit-scrollbar {
  width: 16px;
}

/* for <img class="emoji" ... /> and <svg inside richtext in lessons from scraping TP */
.emoji .svg-inline--fa {
  margin: 0;
  width: 20px;
  height: 16px;
}

/* for iframe vimeos in lessons from scraping TP */
.embed-responsive-item {
  @apply flex w-full h-fit sm:h-64 xl:h-[400px];
}

/* for youtube embeds in richtext in lessons from scraping TP */
.video-embed > * > * {
  width: 100%;
}

/* for colored div richtext in lessons from scraping TP */
.card {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-body {
  min-height: 1px;
  padding: 1.25rem;
}

.bg-info {
  background-color: #17a2b8 !important;
}
.bg-warning {
  background-color: #f9b025 !important;
}
.bg-danger {
  background-color: #ef244c !important;
}
.bg-light {
  background-color: #f8f9fa !important;
}
.bg-dark {
  background-color: #343a40 !important;
}
.bg-light {
  background-color: #f8f9fa !important;
}

/* for "Our Pick" div richtext in lessons from scraping TP */
.products-wrapper {
  font-family:
    Open Sans,
    sans-serif;
  margin: 30px 0;
  position: relative;
  background-color: white;
  border-radius: 12px;
  box-shadow: 2px 4px 3px rgba(0, 0, 0, 0.1);
}
.block-title {
  background-color: #f9b025;
  color: #324057;
  font-size: 16px;
  font-weight: 700;
  left: 0;
  padding: 2px 20px 2px 15px;
  position: absolute;
  top: 0;
  z-index: 1;
  margin-right: -15px;
  margin-left: -10px;
}
.products-wrapper .block-title:after {
  border-left: 10px solid transparent;
  border-top: 10px solid #c08124;
  content: "";
  left: 0;
  position: absolute;
  top: 100%;
}
.row {
  @apply flex flex-col md:flex-row;
}
.product-image {
  @apply m-auto;
}
.product-content {
  @apply p-4;
}
.product-content h4 {
  @apply text-brand-quaternary m-0 mt-2;
}
.product-content h5 {
  @apply text-lg text-brand-secondary m-0;
}
.purchase-links {
  @apply list-none m-0;
}
.purchase-links li a {
  @apply py-1 px-2 rounded text-white font-semibold bg-brand-quaternary hover:bg-brand-primary no-underline;
}
.product-description {
  @apply text-sm mt-4 mb-6;
}
